window.updateTotalPrice = () => {
  let standPrice = document.querySelector("#stand-price .price-euro") ? parseFloat(document.querySelector("#stand-price .price-euro").innerHTML.replace(",", ".")) : 0;
  console.log(standPrice)
  let reservePrice = parseFloat(document.querySelector("#reserve-price .price-euro")?.innerHTML.replace(",", ".")) || 0;
  let elecPrice = parseFloat(document.querySelector("#elec-price .price-euro")?.innerHTML.replace(",", ".") || 0);
  let eauPrice = parseFloat(document.querySelector("#eau-price .price-euro")?.innerHTML.replace(",", ".") || 0);
  let internetPrice = parseFloat(document.querySelector("#internet-price .price-euro")?.innerHTML.replace(",", ".") || 0);
  let wifiPrice = parseFloat(document.querySelector("#wifi-price .price-euro")?.innerHTML.replace(",", ".") || 0);
  let parkingPrice = parseFloat(document.querySelector("#parking-price .price-euro")?.innerHTML.replace(",", ".") || 0);
  let elinguesPrice = parseFloat(document.querySelector("#elingues-price .price-euro")?.innerHTML.replace(",", ".") || 0);

  let totalPrice = standPrice + reservePrice + elecPrice + eauPrice + internetPrice + wifiPrice + elinguesPrice + parkingPrice;
  console.log(totalPrice)
  if (document.querySelector("#total-price .price-euro")) {
    document.querySelector("#total-price .price-euro").innerHTML = totalPrice.toFixed(2).toString().replace(".",",");
  }
  let totalPriceStripe = document.getElementById("commands_total_price");
  if (totalPriceStripe) {
    totalPriceStripe.value = totalPrice.toFixed(2);
  }
};

const initUpdateCommandsPrices = () => {

  // Reserve
  window.updateReservePrice = () => {
    let reserveInput = document.getElementById("commands_reserve") || document.getElementById("estimate_reserve");
    if (reserveInput) {
      reserveInput.addEventListener("change", (e) => {
        document.getElementById("reserve_price").value=`${reserveInput.value}`;
        Rails.fire(document.querySelector("form.reserve"), "submit")
      });
    }
  };

  // Elec
  window.updateElecPrice = () => {
    let elecCheckboxes = document.querySelectorAll(".commands_elec .form-check-input")
    if (elecCheckboxes.length === 0) {
      elecCheckboxes = document.querySelectorAll(".estimate_elec .form-check-input");
    }
    const elec10i = document.getElementById("commands_quantite_10kW_inter") || document.getElementById("estimate_quantite_10kW_inter");
    const elec10p = document.getElementById("commands_quantite_10kW_perm") || document.getElementById("estimate_quantite_10kW_perm");
    const elec20i = document.getElementById("commands_quantite_20kW_inter") || document.getElementById("estimate_quantite_10kW_inter");
    const elec20p = document.getElementById("commands_quantite_20kW_perm") || document.getElementById("estimate_quantite_10kW_perm");
    const elec3i = document.getElementById("commands_quantite_3kW_inter") || document.getElementById("estimate_quantite_3kW_inter");
    const elec3p = document.getElementById("commands_quantite_3kW_perm") || document.getElementById("estimate_quantite_3kW_perm");
    const elec4i = document.getElementById("commands_quantite_4kW_inter") || document.getElementById("estimate_quantite_4kW_inter");
    const elec4p = document.getElementById("commands_quantite_4kW_perm") || document.getElementById("estimate_quantite_4kW_perm");
    const elec6i = document.getElementById("commands_quantite_6kW_inter") || document.getElementById("estimate_quantite_6kW_inter");
    const elec6p = document.getElementById("commands_quantite_6kW_perm") || document.getElementById("estimate_quantite_6kW_perm");
    const elec8i = document.getElementById("commands_quantite_8kW_inter") || document.getElementById("estimate_quantite_8kW_inter");
    const elec8p = document.getElementById("commands_quantite_8kW_perm") || document.getElementById("estimate_quantite_8kW_perm");
    const elecp17 = document.getElementById("commands_quantite_p17") || document.getElementById("estimate_quantite_p17");
    const elementsToListenTo = [Array.from(elecCheckboxes), elec10i, elec10p, elec20i, elec20p, elec3i, elec3p, elec4i, elec4p, elec6i, elec6p, elec8i, elec8p, elecp17].flat();
    elementsToListenTo.forEach((element) => {
      if (element) {
        element.addEventListener('change', (e) => {
          let checkedElecBoxes = "";
          elecCheckboxes.forEach((cb) => {
            if (cb.checked) {
              checkedElecBoxes += (checkedElecBoxes === "") ? cb.value : "," + cb.value;
            }
          })
          document.getElementById("elec_price").value=`${checkedElecBoxes}`;
          document.getElementById("elec_elec_10kW_inter_quantity").value=`${elec10i.value}`;
          document.getElementById("elec_elec_10kW_perm_quantity").value=`${elec10p.value}`;
          document.getElementById("elec_elec_20kW_inter_quantity").value=`${elec20i.value}`;
          document.getElementById("elec_elec_20kW_perm_quantity").value=`${elec20p.value}`;
          document.getElementById("elec_elec_3kW_inter_quantity").value=`${elec3i.value}`;
          document.getElementById("elec_elec_3kW_perm_quantity").value=`${elec3p.value}`;
          document.getElementById("elec_elec_4kW_inter_quantity").value=`${elec4i.value}`;
          document.getElementById("elec_elec_4kW_perm_quantity").value=`${elec4p.value}`;
          document.getElementById("elec_elec_6kW_inter_quantity").value=`${elec6i.value}`;
          document.getElementById("elec_elec_6kW_perm_quantity").value=`${elec6p.value}`;
          document.getElementById("elec_elec_8kW_inter_quantity").value=`${elec8i.value}`;
          document.getElementById("elec_elec_8kW_perm_quantity").value=`${elec8p.value}`;
          document.getElementById("elec_elec_p17_quantity").value=`${elecp17.value}`;
          // document.querySelector("form.elec").submit();
          Rails.fire(document.querySelector("form.elec"), "submit");
        });
      }
    })
  };

  // Eau
  window.updateEauPrice = () => {
    let eauCheckboxes = document.querySelectorAll(".commands_eau .form-check-input");
    if (eauCheckboxes.length === 0) {
      eauCheckboxes = document.querySelectorAll(".estimate_eau .form-check-input");
    }
    const eauEvierEvac = document.getElementById("commands_quantite_eau_evier_evac") || document.getElementById("estimate_quantite_eau_evier_evac");
    const eauBranchement = document.getElementById("commands_quantite_eau_branchement") || document.getElementById("estimate_quantite_eau_branchement");
    const evierSeul = document.getElementById("commands_quantite_evier_seul") || document.getElementById("estimate_quantite_evier_seul");
    const elementsToListenTo = [Array.from(eauCheckboxes), eauEvierEvac, eauBranchement, evierSeul].flat();

    elementsToListenTo.forEach((element) => {
      if (element) {
        element.addEventListener('change', (e) => {
          let checkedEauBoxes = "";
          eauCheckboxes.forEach((cb) => {
            if (cb.checked) {
              checkedEauBoxes += (checkedEauBoxes === "") ? cb.value : "," + cb.value;
            }
          })
          document.getElementById("eau_price").value=`${checkedEauBoxes}`;
          document.getElementById("eau_eau_evier_evac_quantity").value=`${eauEvierEvac.value}`;
          document.getElementById("eau_eau_branchement_quantity").value=`${eauBranchement.value}`;
          document.getElementById("eau_evier_quantity").value=`${evierSeul.value}`;
          Rails.fire(document.querySelector("form.eau"), "submit");
        });
      }
    })
  };

  // Internet
  window.updateInternetPrice = () => {
    let internetCheckboxes = document.querySelectorAll(".commands_internet .form-check-input");
    if (internetCheckboxes.length === 0) {
      internetCheckboxes = document.querySelectorAll(".estimate_internet .form-check-input");
    }
    const inputRJ4510 = document.getElementById("commands_quantite_rj45_10") || document.getElementById("estimate_quantite_rj45_10");
    const inputRJ4520 = document.getElementById("commands_quantite_rj45_20") || document.getElementById("estimate_quantite_rj45_20");
    const elementsToListenTo = [Array.from(internetCheckboxes), inputRJ4510, inputRJ4520].flat();

    elementsToListenTo.forEach((element) => {
      if (element) {
        element.addEventListener('change', (e) => {
          let checkedInternetBoxes = "";
          internetCheckboxes.forEach((cb) => {
            if (cb.checked) {
              checkedInternetBoxes += (checkedInternetBoxes === "") ? cb.value : "," + cb.value;
            }
          })
          document.getElementById("internet_price").value=`${checkedInternetBoxes}`;
          document.getElementById("internet_rj4510_quantity").value=`${inputRJ4510.value}`;
          document.getElementById("internet_rj4520_quantity").value=`${inputRJ4520.value}`;
          Rails.fire(document.querySelector("form.internet"), "submit");
        });
      }
    })
  };

  // Wifi
  window.updateWifiPrice = () => {
    let wifiCheckboxes = document.querySelectorAll(".commands_wifi .form-check-input");
    if (wifiCheckboxes.length === 0) {
      wifiCheckboxes = document.querySelectorAll(".estimate_wifi .form-check-input");
    }
    wifiCheckboxes.forEach((checkbox) => {
      if (checkbox) {
        checkbox.addEventListener('change', (e) => {
          let checkedWifiBoxes = "";
          wifiCheckboxes.forEach((cb) => {
            if (cb.checked) {
              checkedWifiBoxes += (checkedWifiBoxes === "") ? cb.value : "," + cb.value;
            }
          })
          document.getElementById("wifi_price").value=`${checkedWifiBoxes}`;
          Rails.fire(document.querySelector("form.wifi"), "submit");
        });
      }
    })
  };

  // Parking
  window.updateParkingPrice = () => {
    let parkingCheckboxes = document.querySelectorAll(".commands_parking .form-check-input");
    if (parkingCheckboxes.length === 0) {
      parkingCheckboxes = document.querySelectorAll(".estimate_parking .form-check-input");
    }
    const parkingF = document.getElementById("commands_quantite_parking_F") || document.getElementById("estimate_quantite_parking_F");
    const elementsToListenTo = [Array.from(parkingCheckboxes), parkingF].flat();

    elementsToListenTo.forEach((element) => {
      if (element) {
        element.addEventListener('change', (e) => {
          let checkedParkingBoxes = "";
          parkingCheckboxes.forEach((cb) => {
            if (cb.checked) {
              checkedParkingBoxes += (checkedParkingBoxes === "") ? cb.value : "," + cb.value;
            }
          })
          document.getElementById("parking_price").value=`${checkedParkingBoxes}`;
          document.getElementById("parking_parking_F_quantity").value=`${parkingF.value}`;
          Rails.fire(document.querySelector("form.parking"), "submit");
        });
      }
    })
  };

  // Elingues
  window.updateElinguesPrice = () => {
    let elinguesCheckboxes = document.querySelectorAll(".commands_elingues .form-check-input");
    if (elinguesCheckboxes.length === 0) {
      elinguesCheckboxes = document.querySelectorAll(".estimate_elingues .form-check-input");
    }
    const elingues = document.getElementById("commands_quantite_elingues") || document.getElementById("estimate_quantite_elingues");
    const descAlim = document.getElementById("commands_quantite_desc_alim") || document.getElementById("estimate_quantite_desc_alim");
    const elementsToListenTo = [Array.from(elinguesCheckboxes), elingues, descAlim].flat();

    elementsToListenTo.forEach((element) => {
      if (element) {
        element.addEventListener('change', (e) => {
          let checkedElinguesBoxes = "";
          elinguesCheckboxes.forEach((cb) => {
            if (cb.checked) {
              checkedElinguesBoxes += (checkedElinguesBoxes === "") ? cb.value : "," + cb.value;
            }
          })
          document.getElementById("elingues_price").value=`${checkedElinguesBoxes}`;
          document.getElementById("elingues_elingues_quantity").value=`${elingues.value}`;
          document.getElementById("elingues_desc_alim_quantity").value=`${descAlim.value}`;
          Rails.fire(document.querySelector("form.elingues"), "submit");
        });
      }
    })
  };

   // Stand (for Estimates only)
  window.updateStandPrice = () => {
    let standInput = document.getElementById("estimate_stand");
    standInput.addEventListener("change", (e) => {
      document.querySelector("#stand-price .price-euro").innerHTML=`${(parseInt(standInput.value)*2897.05).toFixed(2)}`;
      // Rails.fire(document.querySelector("form.reserve"), "submit")
    });
  };

  

  // To update prices in the additional commands section
  let additionalCommands = document.querySelector('.new_orders__container')
  if (additionalCommands) {
    window.updateReservePrice();
    window.updateElecPrice();
    window.updateEauPrice();
    window.updateInternetPrice();
    window.updateWifiPrice();
    window.updateParkingPrice();
    window.updateElinguesPrice();
    window.updateTotalPrice();
  }
};

export { initUpdateCommandsPrices };
